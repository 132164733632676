// Windows XP
export const WINDOWS_XP = 1;
// Windows Vista
export const WINDOWS_VISTA = 2;
// Xbox360
export const XBOX_360 = 3;
// Windows 7
export const WINDOWS_7 = 4;
// Windows RT
export const WINDOWS_RT = 5;
// Windows 8
export const WINDOWS_8 = 6;
// Windows Other
export const WINDOWS_OTHER = 7;
// Windows Phone
export const WINDOWS_PHONE = 8;
// iPad
export const IPAD = 9;
// iPhone, iPad touch
export const IPHONE = 10;
// Intel Mac
export const INTEL_MAC = 11;
// Mac Other
export const MAC_OTHER = 12;
// Android mobile
export const ANDROID_MOBILE = 13;
// Android tablet
export const ANDROID_TABLET = 14;
// Android Other
export const ANDROID_OTHER = 15;
// Chromecast
export const CHROMECAST = 16;
// VIERA HLS
export const VIERA_HLS = 17;
// VIERA
export const VIERA = 18;
// BRAVIA
export const BRAVIA = 19;
// WiiU
export const WIIU = 20;
// Xbox One
export const XBOX_ONE = 21;
// Windows 10
export const WINDOWS_10 = 22;
// PlayStation 4
export const PS_4 = 23;
// PlayStation Vita
export const PS_VITA = 24;
// Android TV
export const ANDROID_TV = 25;
// Apple TV
export const APPLE_TV = 26;
// REGZA
export const REGZA = 27;
// Fire TV
export const FIRE_TV = 28;
// Other
export const OTHER = 100;
