/* eslint-disable */
import { AspidaClient, dataToURLString } from 'aspida';
import { Methods as Methods0 } from './ams/token';
import { Methods as Methods1 } from './bookmark/isbookmark';
import { Methods as Methods2 } from './content/child_list';
import { Methods as Methods3 } from './content/detailinfomulti';
import { Methods as Methods4 } from './content/playinfo';
import { Methods as Methods5 } from './content/playing';
import { Methods as Methods6 } from './pl/game_info';
import { Methods as Methods7 } from './pl/scheduleMonth';
import { Methods as Methods8 } from './purchase/confirm';
import { Methods as Methods9 } from './unit/list';
import { Methods as Methods10 } from './web/bookmark';
import { Methods as Methods11 } from './web/bookmark/deleteByContentId';
import { Methods as Methods12 } from './web/content_detail';
import { Methods as Methods13 } from './web/search';
import { Methods as Methods14 } from './web/view_history';
import { Methods as Methods15 } from './member/debug_info';
import { Methods as Methods16 } from './pl/ranking';

const { NEXT_PUBLIC_IS_MOCK } = process.env;
const isMock = NEXT_PUBLIC_IS_MOCK === 'true';
const extenstion = '.json';

// prettier-ignore
const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? 'http://localhost:3000/' : baseURL).replace(/\/$/, '')
  const PATH0 = isMock ? '/ams/token' : `/ams_token/get${extenstion}`
  const PATH1 = isMock ? '/bookmark/isbookmark' : `/bookmark/isbookmark${extenstion}`
  const PATH2 = isMock ? '/content/child_list' : `/content/child_list${extenstion}`
  const PATH3 = isMock ? '/content/detailinfomulti' : `/content/detailinfomulti${extenstion}`
  const PATH4 = isMock ? '/content/playinfo' : `/content/playinfo${extenstion}`
  const PATH5 = isMock ? '/content/playing' : `/content/playing${extenstion}`
  const PATH6 = isMock ? '/pl/game_info' : `/pl/game_info${extenstion}`
  const PATH7 = isMock ? '/pl/scheduleMonth' : `/pl/scheduleMonth${extenstion}`
  const PATH8 = isMock ? '/purchase/confirm' : `/purchase/confirm${extenstion}`
  const PATH9 = isMock ? '/unit/list' : `/unit/list${extenstion}`
  const PATH10 = isMock ? '/web/bookmark' : `/web/bookmark${extenstion}`
  const PATH11 = isMock ? '/web/bookmark/deleteByContentId' : `/web/bookmark/deleteByContentId${extenstion}`
  const PATH12 = isMock ? '/web/content_detail' : `/web/content_detail${extenstion}`
  const PATH13 = isMock ? '/web/search' : `/web/search${extenstion}`
  const PATH14 = isMock ? '/web/view_history' : `/web/view_history${extenstion}`
  const PATH15 = isMock ? '/member/debug_info' : `/member/debug_info${extenstion}`
  const PATH16 = isMock ? '/pl/ranking' : `/pl/ranking${extenstion}`
  const GET = 'GET'
  const POST = 'POST'
  const DELETE = 'DELETE'

  return {
    ams: {
      token: {
        /**
         * @returns get ams token successfully.
         */
        get: (option: { query: Methods0['get']['query'], config?: T }) =>
          fetch<Methods0['get']['resBody'], Methods0['get']['resHeaders'], Methods0['get']['status']>(prefix, PATH0, GET, option).json(),
        /**
         * @returns get ams token successfully.
         */
        $get: (option: { query: Methods0['get']['query'], config?: T }) =>
          fetch<Methods0['get']['resBody'], Methods0['get']['resHeaders'], Methods0['get']['status']>(prefix, PATH0, GET, option).json().then(r => r.body),
      }
    },
    bookmark: {
      isbookmark: {
        /**
         * @returns get is bookmark successfully.
         */
        get: (option: { query: Methods1['get']['query'], config?: T }) =>
          fetch<Methods1['get']['resBody'], Methods1['get']['resHeaders'], Methods1['get']['status']>(prefix, PATH1, GET, option).json(),
        /**
         * @returns get is bookmark successfully.
         */
        $get: (option: { query: Methods1['get']['query'], config?: T }) =>
          fetch<Methods1['get']['resBody'], Methods1['get']['resHeaders'], Methods1['get']['status']>(prefix, PATH1, GET, option).json().then(r => r.body),
      },
    },
    content: {
      child_list: {
        /**
         * @returns get content child list successfully.
         */
        get: (option: { query: Methods2['get']['query'], config?: T }) =>
          fetch<Methods2['get']['resBody'], Methods2['get']['resHeaders'], Methods2['get']['status']>(prefix, PATH2, GET, option).json(),
        /**
         * @returns get content child list successfully.
         */
        $get: (option: { query: Methods2['get']['query'], config?: T }) =>
          fetch<Methods2['get']['resBody'], Methods2['get']['resHeaders'], Methods2['get']['status']>(prefix, PATH2, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get'; query: Methods2['get']['query'] }) =>
          `${prefix}${PATH2}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      detailinfomulti: {
        /**
         * @returns get content detail info successfully.
         */
        get: (option: { query: Methods3['get']['query'], config?: T }) =>
          fetch<Methods3['get']['resBody'], Methods3['get']['resHeaders'], Methods3['get']['status']>(prefix, PATH3, GET, option).json(),
        /**
         * @returns get content detail info successfully.
         */
        $get: (option: { query: Methods3['get']['query'], config?: T }) =>
          fetch<Methods3['get']['resBody'], Methods3['get']['resHeaders'], Methods3['get']['status']>(prefix, PATH3, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get'; query: Methods3['get']['query'] }) =>
          `${prefix}${PATH3}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      playinfo: {
        /**
         * @returns get playinfo successfully.
         */
        get: (option: { query: Methods4['get']['query'], config?: T }) =>
          fetch<Methods4['get']['resBody'], Methods4['get']['resHeaders'], Methods4['get']['status']>(prefix, PATH4, GET, option).json(),
        /**
         * @returns get playinfo successfully.
         */
        $get: (option: { query: Methods4['get']['query'], config?: T }) =>
          fetch<Methods4['get']['resBody'], Methods4['get']['resHeaders'], Methods4['get']['status']>(prefix, PATH4, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get'; query: Methods4['get']['query'] }) =>
          `${prefix}${PATH4}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      playing: {
        /**
         * @returns resume results successfully.
         */
        post: (option: { body: Methods5['post']['reqBody'], config?: T }) =>
          fetch<Methods5['post']['resBody'], Methods5['post']['resHeaders'], Methods5['post']['status']>(prefix, PATH5, POST, option, 'URLSearchParams').json(),
        $post: (option: { body: Methods5['post']['reqBody'], config?: T }) =>
          fetch<Methods5['post']['resBody'], Methods5['post']['resHeaders'], Methods5['post']['status']>(prefix, PATH5, POST, option, 'URLSearchParams').json().then(r => r.body),
      },
    },
    pl: {
      gameInfo: {
        /**
         * @returns get search results successfully.
         */
        get: (option: { query: Methods6['get']['query'], config?: T }) =>
          fetch<Methods6['get']['resBody'], Methods6['get']['resHeaders'], Methods6['get']['status']>(prefix, PATH6, GET, option).json(),
        /**
         * @returns get search results successfully.
         */
        $get: (option: { query: Methods6['get']['query'], config?: T }) =>
          fetch<Methods6['get']['resBody'], Methods6['get']['resHeaders'], Methods6['get']['status']>(prefix, PATH6, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get'; query: Methods6['get']['query'] }) =>
          `${prefix}${PATH6}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      scheduleMonth: {
        /**
         * @returns get search results successfully.
         */
        get: (option: { query: Methods7['get']['query'], config?: T }) =>
          fetch<Methods7['get']['resBody'], Methods7['get']['resHeaders'], Methods7['get']['status']>(prefix, PATH7, GET, option).json(),
        /**
         * @returns get search results successfully.
         */
        $get: (option: { query: Methods7['get']['query'], config?: T }) =>
          fetch<Methods7['get']['resBody'], Methods7['get']['resHeaders'], Methods7['get']['status']>(prefix, PATH7, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get'; query: Methods7['get']['query'] }) =>
          `${prefix}${PATH7}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      ranking: {
        /**
         * @returns get search results successfully.
         */
        get: (option: { config?: T }) =>
          fetch<Methods16['get']['resBody'], Methods16['get']['resHeaders'], Methods16['get']['status']>(prefix, PATH16, GET, option).json(),
        /**
         * @returns get search results successfully.
         */
        $get: (option: { config?: T }) =>
          fetch<Methods16['get']['resBody'], Methods16['get']['resHeaders'], Methods16['get']['status']>(prefix, PATH16, GET, option).json().then(r => r.body),
        $path: () =>
          `${prefix}${PATH16}`
      },
    },
    purchase: {
      confirm: {
        /**
         * @returns get search results successfully.
         */
        get: (option: { query: Methods8['get']['query'], config?: T }) =>
          fetch<Methods8['get']['resBody'], Methods8['get']['resHeaders'], Methods8['get']['status']>(prefix, PATH8, GET, option).json(),
        /**
         * @returns get search results successfully.
         */
        $get: (option: { query: Methods8['get']['query'], config?: T }) =>
          fetch<Methods8['get']['resBody'], Methods8['get']['resHeaders'], Methods8['get']['status']>(prefix, PATH8, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get'; query: Methods8['get']['query'] }) =>
          `${prefix}${PATH8}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
    },
    unit: {
      list: {
        /**
         * @returns get search results successfully.
         */
        get: (option: { query: Methods9['get']['query'], config?: T }) =>
          fetch<Methods9['get']['resBody'], Methods9['get']['resHeaders'], Methods9['get']['status']>(prefix, PATH9, GET, option).json(),
        /**
         * @returns get search results successfully.
         */
        $get: (option: { query: Methods9['get']['query'], config?: T }) =>
          fetch<Methods9['get']['resBody'], Methods9['get']['resHeaders'], Methods9['get']['status']>(prefix, PATH9, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get'; query: Methods9['get']['query'] }) =>
          `${prefix}${PATH9}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      }
    },
    web: {
      bookmark: {
        /**
         * @returns register bookmark successfully.
         */
        post: (option: { body: Methods10['post']['reqBody'], config?: T }) =>
          fetch<Methods10['post']['resBody'], Methods10['post']['resHeaders'], Methods10['post']['status']>(prefix, PATH10, POST, option, 'URLSearchParams').json(),
        $post: (option: { body: Methods10['post']['reqBody'], config?: T }) =>
          fetch<Methods10['post']['resBody'], Methods10['post']['resHeaders'], Methods10['post']['status']>(prefix, PATH10, POST, option, 'URLSearchParams').json().then(r => r.body),
        /**
         * @returns delete bookmark successfully.
         */
        deleteByContentId: {
          delete: (option: { body: Methods11['delete']['reqBody'], config?: T }) =>
            fetch<Methods11['delete']['resBody'], Methods11['delete']['resHeaders'], Methods11['delete']['status']>(prefix, PATH11, DELETE, option, 'URLSearchParams').json(),
        }
      },
      content_detail: {
        /**
         * @returns get content detail successfully.
         */
        get: (option: { query: Methods12['get']['query'], config?: T }) =>
          fetch<Methods12['get']['resBody'], Methods12['get']['resHeaders'], Methods12['get']['status']>(prefix, PATH12, GET, option).json(),
        /**
         * @returns get content detail successfully.
         */
        $get: (option: { query: Methods12['get']['query'], config?: T }) =>
          fetch<Methods12['get']['resBody'], Methods12['get']['resHeaders'], Methods12['get']['status']>(prefix, PATH12, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get'; query: Methods12['get']['query'] }) =>
          `${prefix}${PATH12}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      search: {
        /**
         * @returns get search results successfully.
         */
        get: (option: { query: Methods13['get']['query'], config?: T }) =>
          fetch<Methods13['get']['resBody'], Methods13['get']['resHeaders'], Methods13['get']['status']>(prefix, PATH13, GET, option).json(),
        /**
         * @returns get search results successfully.
         */
        $get: (option: { query: Methods13['get']['query'], config?: T }) =>
          fetch<Methods13['get']['resBody'], Methods13['get']['resHeaders'], Methods13['get']['status']>(prefix, PATH13, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get'; query: Methods13['get']['query'] }) =>
          `${prefix}${PATH13}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      view_history: {
        /**
         * @returns get view history successfully.
         */
        get: (option: { query: Methods14['get']['query'], config?: T }) =>
          fetch<Methods14['get']['resBody'], Methods14['get']['resHeaders'], Methods14['get']['status']>(prefix, PATH14, GET, option).json(),
        /**
         * @returns get view history successfully.
         */
        $get: (option: { query: Methods14['get']['query'], config?: T }) =>
          fetch<Methods14['get']['resBody'], Methods14['get']['resHeaders'], Methods14['get']['status']>(prefix, PATH14, GET, option).json().then(r => r.body),
        /**
         * @returns register view history successfully.
         */
        post: (option: { body: Methods14['post']['reqBody'], config?: T }) =>
          fetch<Methods14['post']['resBody'], Methods14['post']['resHeaders'], Methods14['post']['status']>(prefix, PATH14, POST, option, 'URLSearchParams').json(),
        /**
         * @returns register view history successfully.
         */
        $post: (option: { body: Methods14['post']['reqBody'], config?: T }) =>
          fetch<Methods14['post']['resBody'], Methods14['post']['resHeaders'], Methods14['post']['status']>(prefix, PATH14, POST, option, 'URLSearchParams').json().then(r => r.body),
        /**
         * @returns delete view histories successfully.
         */
        delete: (option: { body: Methods14['delete']['reqBody'], config?: T }) =>
            fetch<Methods14['delete']['resBody'], Methods14['delete']['resHeaders'], Methods14['delete']['status']>(prefix, PATH14, DELETE, option, 'URLSearchParams').json(),
        /**
         * @returns delete view histories successfully.
         */
        $delete: (option: { body: Methods14['delete']['reqBody'], config?: T }) =>
          fetch<Methods14['delete']['resBody'], Methods14['delete']['resHeaders'], Methods14['delete']['status']>(prefix, PATH14, DELETE, option, 'URLSearchParams').json().then(r => r.body),
        $path: (option?: { method?: 'get'; query: Methods14['get']['query'] }) =>
          `${prefix}${PATH14}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
    },
    member: {
      debug_info: {
        get: (option: { query: Methods15['get']['query'], config?: T }) =>
          fetch<Methods15['get']['resBody'], Methods15['get']['resHeaders'], Methods15['get']['status']>(prefix, PATH15, GET, option).json(),
        $get: (option: { query: Methods15['get']['query'], config?: T }) =>
          fetch<Methods15['get']['resBody'], Methods15['get']['resHeaders'], Methods15['get']['status']>(prefix, PATH15, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get'; query: Methods15['get']['query'] }) =>
          `${prefix}${PATH9}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      }
    },
  }
}

// prettier-ignore
export type ApiInstance = ReturnType<typeof api>
// prettier-ignore
export default api
