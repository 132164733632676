/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint import/prefer-default-export: 0 */
import * as Device from 'react-device-detect';

import * as DeviceId from '@/modules/constants/device';

export const getDeviceId = (): number => {
  if (typeof window === 'undefined') {
    return DeviceId.OTHER;
  }

  const ua = window?.navigator?.userAgent ?? '';
  if (!ua) return DeviceId.OTHER;

  // Xbox One
  if (ua.indexOf('Windows') !== -1 && ua.indexOf('Xbox One') !== -1) {
    return DeviceId.XBOX_ONE;
  }

  // Xbox360
  if (ua.indexOf('Windows') !== -1 && ua.indexOf('Windows NT 6.1') && ua.indexOf('Xbox') !== -1) {
    return DeviceId.XBOX_360;
  }

  // Windows Phone
  if (Device.isWinPhone) {
    return DeviceId.WINDOWS_PHONE;
  }

  // Windows
  if (Device.isWindows) {
    const version = Device.osVersion.split('.')[0];

    switch (version) {
      case 'XP':
        return DeviceId.WINDOWS_XP;
      case 'Vista':
        return DeviceId.WINDOWS_VISTA;
      case '7':
        return DeviceId.WINDOWS_7;
      case '8':
        return DeviceId.WINDOWS_8;
      case 'RT':
        return DeviceId.WINDOWS_RT;
      case '10':
        return DeviceId.WINDOWS_10;
      default:
        return DeviceId.WINDOWS_OTHER;
    }
  }

  // Android, Kindle
  if (Device.isAndroid) {
    // Kindle(Android tablet, Android mobileと同じ)
    // Androidよりも先に判定する
    if (/\bSilk\b/.test(ua)) {
      if (Device.isTablet) {
        return DeviceId.ANDROID_TABLET;
      }

      return DeviceId.ANDROID_MOBILE;
    }

    if (Device.osVersion) {
      const androidVerList = Device.osVersion.split('.');
      const androidMajorVer = Number(androidVerList[0]);
      const androidMinorVer = androidVerList.length > 1 ? Number(androidVerList[1]) : 0;
      if (androidMajorVer < 4 || (androidMajorVer === 4 && androidMinorVer < 4)) {
        return DeviceId.ANDROID_OTHER;
      }
    }
    if (Device.isTablet) {
      return DeviceId.ANDROID_TABLET;
    }

    return DeviceId.ANDROID_MOBILE;
  }

  if (Device.isIPad13) {
    // iPadOSの場合下記は全てTrueとなる
    // isBrowser
    // isMobile
    // isTablet
    // isDesktop
    // isIOS
    // isIPad13
    return DeviceId.IPAD;
  }

  // iOS
  if (Device.isIOS) {
    if (Device.isTablet) {
      return DeviceId.IPAD;
    }

    return DeviceId.IPHONE;
  }

  // Mac
  if (Device.isMacOs) {
    if (ua.indexOf('Intel Mac') !== -1) {
      return DeviceId.INTEL_MAC;
    }

    // Mac Other
    return DeviceId.MAC_OTHER;
  }

  // Chrome OS
  if (ua.indexOf('CrOS') !== -1) {
    return DeviceId.WINDOWS_10;
  }

  // Other
  return DeviceId.OTHER;
};

type DeviceTypes = 'pc' | 'phone' | 'tablet' | 'ced' | 'tv';
const deviceClassification: { [key: number]: DeviceTypes } = {
  [DeviceId.WINDOWS_XP]: 'pc',
  [DeviceId.WINDOWS_VISTA]: 'pc',
  [DeviceId.WINDOWS_7]: 'pc',
  [DeviceId.WINDOWS_RT]: 'pc',
  [DeviceId.WINDOWS_8]: 'pc',
  [DeviceId.WINDOWS_OTHER]: 'pc',
  [DeviceId.INTEL_MAC]: 'pc',
  [DeviceId.MAC_OTHER]: 'pc',
  [DeviceId.WINDOWS_10]: 'pc',
  [DeviceId.WINDOWS_PHONE]: 'phone',
  [DeviceId.IPHONE]: 'phone',
  [DeviceId.ANDROID_MOBILE]: 'phone',
  [DeviceId.ANDROID_OTHER]: 'phone',
  [DeviceId.IPAD]: 'tablet',
  [DeviceId.ANDROID_TABLET]: 'tablet',
  [DeviceId.XBOX_360]: 'tv',
  [DeviceId.CHROMECAST]: 'tv',
  [DeviceId.VIERA_HLS]: 'tv',
  [DeviceId.VIERA]: 'tv',
  [DeviceId.BRAVIA]: 'tv',
  [DeviceId.WIIU]: 'tv',
  [DeviceId.XBOX_ONE]: 'tv',
  [DeviceId.PS_4]: 'tv',
  [DeviceId.ANDROID_TV]: 'tv',
  [DeviceId.APPLE_TV]: 'tv',
  [DeviceId.REGZA]: 'tv',
  [DeviceId.FIRE_TV]: 'tv',
};
export const getDeviceType = (): DeviceTypes => deviceClassification[getDeviceId()];

export const isIPadDesktop = Device.isIOS && Device.isTablet && Device.isDesktop;

export const isRakutenApp =
  Device.isIOS && window?.navigator?.userAgent.toLowerCase().indexOf('app-jp.co.rakuten') !== -1;
